<template>
  <div class="knowledge">
    <div class="column-L animated fadeInUp">
      <div class="screen">
        <div class="screen-title">
          <div class="left-line"></div>
          <p>农业病虫草害</p>
        </div>
        <div class="calamity-type">
          <div
            :class="{
              'calamity-item': true,
              active: calamityTypeIndex == index,
            }"
            v-for="(item, index) in calamityTypeList"
            :key="index"
            @click="changeCalamityType(index)"
          >
            <img class="calamity-img" :src="item.img" alt="" />
            <p class="calamity-title">{{ item.title }}</p>
          </div>
        </div>
        <div class="calamity-crops-class">
          <el-collapse
            accordion
            @change="changeCalamityClass"
            v-model="CalamityClassId"
          >
            <el-collapse-item
              v-for="(item, index) in cropsClassList"
              :key="index"
              :name="index"
            >
              <template slot="title">
                <div class="know-detailed">
                  <span>{{ item.key }}</span>
                  <div class="know-detailed-arrow">
                    <img
                      class="arrow-img"
                      :class="CalamityClassId === index ? 'active' : ''"
                      src="../assets/image/know/icon11.png"
                    />
                  </div>
                </div>
              </template>
              <div class="know-detailed-content">
                <div class="crops-list">
                  <div
                    :class="{
                      'crops-item': true,
                      active: calamityCropsStyle === item.crop_id,
                    }"
                    v-for="item in item.element"
                    :key="item.crop_id"
                    @click="
                      changeCrops(
                        item.category_id,
                        item.family_id,
                        item.crop_id,
                        item.crop_name
                      )
                    "
                  >
                    {{ item.crop_name }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="result" v-show="!diseaseInfoShow">
        <div class="result-top">
          <div class="result-search">
            <div class="per-class">
              <el-select v-model="calamityTypeOptionValue" placeholder="">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, index) in calamityTypeOption"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
            <div class="name calamity-type-name">
              <el-input
                v-model.trim="calamityTypeName"
                autocomplete="off"
                placeholder="请输入你需要搜索的关键词"
                maxlength="20"
              ></el-input>
            </div>
            <div class="searchBtn" @click="getCalamitySearch">查询</div>
          </div>
          <img
            class="result-chat"
            src="../assets/image/know/chat.png"
            alt=""
            @click="jumpChat"
          />
        </div>
        <!-- 病虫 -->
        <div class="show-box" v-show="!calamityTypeIndex">
          <div
            class="disease-list"
            v-show="disease_data.length || insect_data.length"
          >
            <div class="disease-list-items">
              <div class="disease-list-items-title">
                {{ crop_name }}相关病害共有（{{ disease_count }}）种
              </div>
              <div
                class="disease-list-items-main"
                v-blockLoadMore="loadMoreDisease"
              >
                <div
                  :class="{
                    'disease-list-items-main-items': true,
                    active: changediseaseId === t.diseaseID,
                  }"
                  v-for="t in disease_data"
                  :key="t.diseaseID"
                  @click="changeItems(t.diseaseID)"
                >
                  {{ t.diseaseName }}
                </div>
              </div>
            </div>
            <div class="disease-list-items">
              <div class="disease-list-items-title">
                {{ crop_name }}相关虫害共有（{{ insect_count }}）种
              </div>
              <div
                class="disease-list-items-main"
                v-blockLoadMore="loadMoreInsect"
              >
                <div
                  :class="{
                    'disease-list-items-main-items': true,
                    active: changediseaseId === t.diseaseID,
                  }"
                  v-for="t in insect_data"
                  :key="t.diseaseID"
                  @click="changeItems(t.diseaseID)"
                >
                  {{ t.diseaseName }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="disease-list"
            v-show="!disease_data.length || !insect_data.length"
          >
            <MyNothing></MyNothing>
          </div>
        </div>
        <!-- 杂草 -->
        <div class="show-box" v-show="calamityTypeIndex">
          <div class="disease-list" v-show="weedList.length">
            <div class="disease-list-items">
              <div class="disease-list-items-title">
                {{ crop_name }}相关共有（{{ weed_count }}）种
              </div>
              <div
                class="disease-list-items-main"
                v-blockLoadMore="loadMoreWeed"
              >
                <div
                  :class="{
                    'disease-list-items-main-items': true,
                    active: changediseaseId === t.weedID,
                  }"
                  v-for="t in weedList"
                  :key="t.weedID"
                  @click="changeWeedItems(t.weedID)"
                >
                  {{ t.weedName }}
                </div>
              </div>
            </div>
          </div>
          <div class="disease-list" v-show="!weedList.length">
            <MyNothing></MyNothing>
          </div>
        </div>
      </div>
      <!-- 病虫信息 -->

      <div class="info-box" v-show="diseaseInfoShow && !calamityTypeIndex">
        <div class="info-title">
          <div class="info-left-line">{{ diseaseInfo.diseaseName }}</div>
          <div class="return-btn" @click="diseaseInfoShow = !diseaseInfoShow">
            <img src="../assets/image/know/back.png" alt="" /><span>返回</span>
          </div>
        </div>
        <div class="cell-title">
          <span>简介</span>
        </div>
        <div class="other-col">
          <span class="content-title">英文名</span>
          <span class="content-main">{{ diseaseInfo.diseaseNameEng }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">异 名</span>
          <span class="content-main">{{ diseaseInfo.diseaseNameOther }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">简 介</span>
          <span class="content-main" style="line-height: 30px">{{
            diseaseInfo.diseaseIntro
          }}</span>
        </div>
        <div class="cell-title" v-if="diseaseInfo.diseaseImgList[0]">
          <span>缩图</span>
        </div>
        <div class="imgs-list" v-if="diseaseInfo.diseaseImgList[0]">
          <img
            alt=""
            :src="t"
            v-for="(t, i) in diseaseInfo.diseaseImgList"
            :key="i"
            @click="lookImg(t)"
          />
        </div>
        <div class="cell-title">
          <span>详细资料</span>
        </div>
        <div class="means-list">
          <div
            class="means-list-items"
            v-for="(item, index) in meansList"
            :key="index"
          >
            <p class="p-title">{{ item.title ? item.title : "其他" }}</p>
            <p class="p-value">{{ item.value }}</p>
          </div>
        </div>
      </div>
      <!-- 杂草信息 -->
      <div class="info-box" v-show="diseaseInfoShow && calamityTypeIndex">
        <div class="info-title">
          <div class="info-left-line">{{ weedInfo.weedName }}</div>
          <div class="return-btn" @click="diseaseInfoShow = !diseaseInfoShow">
            <img src="../assets/image/know/back.png" alt="" /><span>返回</span>
          </div>
        </div>
        <div class="cell-title">
          <span>简介</span>
        </div>
        <div class="other-col">
          <span class="content-title">英文名</span>
          <span class="content-main">{{ weedInfo.weedNameEng }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">异 名</span>
          <span class="content-main">{{ weedInfo.weedNameOther }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">简 介</span>
          <span class="content-main" style="line-height: 30px">{{
            weedInfo.weedIntro
          }}</span>
        </div>
        <div class="cell-title" v-if="weedInfo.weedImgList[0]">
          <span>缩图</span>
        </div>
        <div class="imgs-list" v-if="weedInfo.weedImgList[0]">
          <img
            :src="t"
            alt=""
            v-for="(t, i) in weedInfo.weedImgList"
            :key="i"
            @click="lookImg(t)"
          />
        </div>
        <div class="cell-title">
          <span>详细资料</span>
        </div>
        <div class="means-list">
          <div
            class="means-list-items"
            v-for="(item, index) in meansWeedList"
            :key="index"
          >
            <p class="p-title">{{ item.title ? item.title : "其他" }}</p>
            <p class="p-value">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="column-R animated fadeInUp">
      <div class="focus">
        <div class="f-title">
          <img src="../assets/image/know/icon6.png" /><span>农业要闻</span>
          <div></div>
        </div>
        <div class="banner-box">
          <el-carousel
            class="banner-main"
            ref="carousel"
            trigger="click"
            :autoplay="true"
            height="296px"
            @change="changeBanner"
          >
            <el-carousel-item
              v-for="item in bannerList"
              :key="item.id"
              @click.native="seeNews(item.id)"
            >
              <el-image
                class="banner-imgs"
                :src="item.photo ? uploadImgs + item.photo : ''"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="banner-info">
            <span
              :title="item.title"
              class="title one-txt-cut"
              @click="seeNews(item.id)"
              v-for="(item, index) in bannerList"
              :key="item.id"
              v-show="current - 1 === index"
            >
              {{ item.title }}
            </span>
            <div class="control">
              <img
                class="prev-img"
                src="../assets/image/know/icon5.png"
                @click="changePrev"
              />
              <p class="index-count">
                <span>{{ current }}</span
                ><span>/{{ bannerList.length }}</span>
              </p>
              <img
                class="next-img"
                src="../assets/image/know/icon4.png"
                @click="changeNext"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="news">
        <div
          class="r-lists"
          v-if="newList.length"
          v-blockLoadMore="loadMoreNewData"
        >
          <div
            class="r-items"
            v-for="item in newList"
            :key="item.id"
            @click="seeNews(item.id)"
          >
            <div class="summary one-txt-cut" :title="item.title">
              {{ item.title }}
            </div>
            <div class="time">
              <span class="one-txt-cut">{{ item.created_time }}</span>
            </div>
          </div>
        </div>
        <div class="r-lists" v-else>
          <MyNothing></MyNothing>
        </div>
      </div>
    </div>

    <MyNav></MyNav>
    <el-dialog
      class="know-pop"
      title=" "
      :visible.sync="addShow"
      width="908px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div
        class="know-outbox"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="ware-title">
          <div class="left-line"></div>
          <p>
            {{ isShow ? diseaseInfo.diseaseName : articleInfo.title }}
          </p>
        </div>
        <div class="know-main">
          <div class="know-brief-content">
            <div class="know-brief-content-banner">
              <el-carousel class="carousel" trigger="click">
                <el-carousel-item
                  v-for="(item, index) in articleInfo.photo"
                  :key="index"
                >
                  <el-image
                    class="carousel-imgs"
                    :src="uploadImgs + item"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="know-brief-content-other">
              <div class="other-col" v-if="!isShow">
                <span class="content-title">发布时间</span>
                <span class="content-main">{{ articleInfo.created_time }}</span>
              </div>
              <div class="other-col" v-if="!isShow">
                <span class="content-title">查看人次</span>
                <span class="content-main">{{ articleInfo.hits }}</span>
              </div>
            </div>
          </div>

          <div class="know-scroll-box">
            <div
              class="article"
              v-if="!isShow"
              v-html="articleInfo.content"
            ></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="lookDialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getArticleListApi,
  getArticleInfoApi,
  getDiseaseListV2Api,
  getCategoryListV2Api,
  getDiseaseDetailApi,
  getWeedCategoryListApi,
  getWeedListApi,
  getWeedInfoApi,
} from "@/request/api.js";
import { mapState } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      weedList: [],
      weed_count: 0,
      weed_pn: 1,
      weed_ps: 40,
      meansList: [],
      meansWeedList: [],
      diseaseInfoShow: false,
      lookDialogVisible: false,
      dialogImageUrl: "",
      flag: "",
      crop_name: "",
      changediseaseId: undefined,
      cid: undefined,
      fid: undefined,
      calamityTypeName: undefined,
      calamityTypeOptionValue: "病虫害",
      calamityTypeOption: ["病虫害", "草害"],
      cropsClassList: [],
      calamityCropsId: undefined,
      calamityCropsStyle: undefined,
      CalamityClassId: undefined,
      calamityTypeIndex: 0,
      calamityTypeList: [
        {
          title: "病虫害分类",
          img: require("../assets/image/know/icon1.png"),
        },
        {
          title: "杂草分类",
          img: require("../assets/image/know/icon2.png"),
        },
      ],
      cropsList: [
        { title: "大麦", id: 1 },
        { title: "小麦", id: 2 },
      ],
      loading: false,
      // 推荐文章
      bannerList: [],
      current: 1,
      // 最新消息
      newList: [],

      // 查询列表
      diseaseList: [],

      // 弹窗
      addShow: false,
      isShow: true,
      PopTitle: "",
      // 详细资料
      isDetailShow: false,
      // 最新新闻分页
      newTotal: 10,
      newCurrent_page: 1,
      // 推荐新闻分页
      bannerTotal: 10,
      bannerCurrent_page: 1,

      disease_count: 0,
      disease_data: [],
      disease_pn: 1,
      disease_ps: 10,

      insect_count: 0,
      insect_data: [],
      insect_pn: 1,
      insect_ps: 10,
      // 病虫害类型
      diseaseTypeList: [],
      // 作物列表
      familyList: [],
      // 作物分类列表
      categoryList: [],
      // 作物列表
      cropList: [],
      // 病虫害详情
      diseaseInfo: {
        diseaseName: "",
        diseaseNameEng: "",
        diseaseNameOther: "",
        diseaseIntro: "",
        diseaseTypeID: 1,
        diseaseDetails: {
          DamageSym: "",
          OFactor: "",
          Pathogen: "",
          Cycle: "",
          Morphology: "",
          Habits: "",
          CMethod: "",
        },
        diseaseImgList: [],
      },
      weedInfo: { weedImgList: [] },

      // 文章详情
      articleInfo: {},
      radioVal: "",
      // 延时器
      timeout: null,
    };
  },
  watch: {
    calamityTypeOptionValue: {
      handler(newVal) {
        if (newVal === "草害") {
          this.calamityTypeIndex = 1;
        } else {
          this.calamityTypeIndex = 0;
        }
      },
    },
    calamityTypeIndex: {
      handler(newVal) {
        this.cropsClassList = [];
        this.diseaseInfoShow = false;
        if (newVal) {
          this.weedList = [];
          this.calamityTypeOptionValue = "草害";
          this.getWeedCategoryListApi();
        } else {
          this.disease_data = [];
          this.insect_data = [];
          this.calamityTypeOptionValue = "病虫害";
          this.getCategoryList();
        }
      },
    },
    diseaseInfoShow(newVal) {
      if (!newVal) {
        this.meansList = [];
        this.meansWeedList = [];
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },

  created() {
    this.getCategoryList();
    this.getArticleListApiRecommend();
    this.getArticleListApiTime();
  },

  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
  },
  methods: {
    // 眼
    jumpChat() {
      this.$message("即将上线");
      // this.$router.push({
      //   name: "dialogue",
      // });
    },
    loadMoreWeed() {
      if (this.weedList.length && this.weedList.length == this.weed_count) {
        return;
      }
      this.weed_pn++;
      this.getWeedListApi(this.weed_pn);
    },
    async getWeedListApi(pn = 1) {
      let temp = {};
      temp.cid = this.cid || null;
      temp.fid = this.fid || null;
      temp.ps = 40;
      temp.weed_id = this.calamityCropsId || null;
      temp.pn = pn;
      temp.title = this.calamityTypeName;
      const { code, results } = await getWeedListApi(temp);
      if (code !== 0) return;
      this.weedList = [...this.weedList, ...results.data];
      this.weed_pn = results.pn;
      this.weed_count = results.count;
    },
    lookImg(src) {
      this.dialogImageUrl = src;
      this.lookDialogVisible = true;
    },
    async changeWeedItems(weed_id) {
      this.loading = true;
      const { code, results } = await getWeedInfoApi({ weed_id });
      if (code !== 0) return;
      for (const key in results.weedDetails) {
        if (Object.hasOwnProperty.call(results.weedDetails, key)) {
          let element = results.weedDetails[key];
          let obj = {};
          if (element) {
            if (element.indexOf("]") != -1) {
              obj.title = element.slice(
                element.indexOf("[") + 1,
                element.indexOf("]")
              );
              obj.value = element.slice(element.indexOf("]") + 1);
            } else {
              obj.title = "";
              obj.value = element;
            }
            this.meansWeedList.push(obj);
          }
        }
      }
      this.weedInfo = results;
      this.changediseaseId = weed_id;
      this.loading = false;
      this.diseaseInfoShow = true;
    },
    async changeItems(disease_id) {
      this.changediseaseId = disease_id;
      this.loading = true;
      const { code, results } = await getDiseaseDetailApi({
        disease_id,
        title: this.calamityTypeName,
      });
      if (code === 0) {
        for (const key in results.diseaseDetails) {
          if (Object.hasOwnProperty.call(results.diseaseDetails, key)) {
            let element = results.diseaseDetails[key];
            let obj = {};
            if (element) {
              if (element.indexOf("]") != -1) {
                obj.title = element.slice(
                  element.indexOf("[") + 1,
                  element.indexOf("]")
                );
                obj.value = element.slice(element.indexOf("]") + 1);
              } else {
                obj.title = "";
                obj.value = element;
              }
              this.meansList.push(obj);
            }
          }
        }
        this.diseaseInfo = results;
        this.loading = false;
        this.diseaseInfoShow = true;
      }
    },

    getCalamitySearch() {
      if (!this.calamityTypeName) {
        this.$notify.error({
          title: "错误",
          message: "请输入关键词",
        });
        return;
      }
      this.crop_name = this.calamityTypeName;
      this.cid = null;
      this.fid = null;
      this.calamityCropsId = null;
      this.calamityCropsStyle = null;
      if (this.calamityTypeOptionValue === "病虫害") {
        this.disease_data = [];
        this.insect_data = [];
        this.commitForm();
      } else {
        this.weedList = [];
        this.getWeedListApi();
      }
    },
    changeCalamityClass(e) {
      this.CalamityClassId = e;
    },
    changeCrops(cid, fid, crop_id, name) {
      this.calamityTypeName = undefined;
      this.crop_name = name;
      this.diseaseInfoShow = false;
      this.calamityCropsId = crop_id;
      this.calamityCropsStyle = crop_id;
      this.cid = cid;
      this.fid = fid;
      if (this.calamityTypeIndex) {
        this.weedList = [];
        this.weed_ps = 1;
        this.getWeedListApi();
      } else {
        this.disease_data = [];
        this.insect_data = [];
        this.disease_ps = 1;
        this.insect_ps = 1;
        this.commitForm();
      }
    },
    changeCalamityType(index) {
      this.CalamityClassId = undefined;
      this.calamityTypeIndex = index;
    },
    // 获取病虫侧边列表
    async getCategoryList() {
      const { results } = await getCategoryListV2Api();
      this.categoryList = results;
      for (const key in results) {
        if (Object.hasOwnProperty.call(results, key)) {
          const element = results[key];
          this.cropsClassList.push({ key, element });
        }
      }
      // 默认展开 禾本科
      this.CalamityClassId = this.cropsClassList.findIndex((item) => {
        return item.key == "农作物-禾本科";
      });
      let { category_id, family_id, crop_id, crop_name } = this.cropsClassList[
        this.CalamityClassId
      ].element.find((el) => {
        return el.crop_name === "水稻";
      });

      this.changeCrops(category_id, family_id, crop_id, crop_name);
      this.$forceUpdate();
    },
    async getWeedCategoryListApi() {
      const { results } = await getWeedCategoryListApi();
      this.categoryList = results;
      for (const key in results) {
        if (Object.hasOwnProperty.call(results, key)) {
          const element = results[key];
          this.cropsClassList.push({ key, element });
        }
      }
      this.$forceUpdate();
    },

    // 获取文章详情
    async getArticleInfoApi(article_id) {
      this.loading = true;
      const { code, results } = await getArticleInfoApi({
        article_id,
      });
      if (code === 0) {
        results.photo = results.photo.split(" ");
        results.content = results.content.replace(
          /<\s*img/g,
          '<img style="width:98%;display: block;"'
        );
        this.articleInfo = results;
        this.loading = false;
      }
    },
    // 获取文章列表-推荐
    async getArticleListApiRecommend() {
      const { code, results } = await getArticleListApi({
        isindex: 1,
      });
      if (code === 0) {
        this.bannerList = [...this.bannerList, ...results.data];
        this.bannerCurrent_page = results.pn;
        this.bannerTotal = results.count;
      }
    },
    // 获取文章列表-最新
    async getArticleListApiTime(pn = 1) {
      const { code, results } = await getArticleListApi({
        time_sort: 1,
        pn,
      });
      if (code === 0) {
        this.newList = [...this.newList, ...results.data];
        this.newCurrent_page = results.pn;
        this.newTotal = results.count;
      }
    },

    loadMoreDisease() {
      if (
        this.disease_data.length &&
        this.disease_data.length == this.disease_count
      ) {
        return;
      }
      this.disease_pn++;
      this.flag = "disease";
      this.commitForm(this.disease_pn, this.insect_pn, this.flag);
    },
    loadMoreInsect() {
      if (
        this.insect_data.length &&
        this.insect_data.length == this.insect_count
      )
        return;
      this.insect_pn++;
      this.flag = "insect";

      this.commitForm(this.disease_pn, this.insect_pn, this.flag);
    },
    loadMoreNewData() {
      if (this.newList.length && this.newList.length == this.newTotal) return;
      this.newCurrent_page++;
      this.getArticleListApiTime(this.newCurrent_page);
    },
    // 切换详细资料状态
    changeCollapse() {
      this.isDetailShow = !this.isDetailShow;
    },
    // 关闭窗口
    setAddClose() {
      this.addShow = false;
    },
    // 查看信息
    seeNews(id) {
      this.getArticleInfoApi(id);
      this.isShow = false;
      this.addShow = true;
    },
    changeBanner(val) {
      this.current = val + 1;
    },
    // 轮播切换
    changePrev() {
      this.$refs.carousel.prev();
      this.current -= 1;
      if (this.current < 1) {
        this.current = this.bannerList.length;
      }
    },
    // 轮播切换
    changeNext() {
      this.$refs.carousel.next();
      this.current += 1;
      if (this.current > this.bannerList.length) {
        this.current = 1;
      }
    },

    async commitForm(disease_pn = 1, insect_pn = 1, flag = "") {
      let temp = {};
      temp.cid = this.cid || null;
      temp.fid = this.fid || null;
      temp.crop_id = this.calamityCropsId || null;
      temp.disease_ps = 40;
      temp.insect_ps = 40;
      temp.disease_pn = disease_pn;
      temp.insect_pn = insect_pn;
      const { code, results } = await getDiseaseListV2Api({
        title: this.calamityTypeName,

        ...temp,
      });
      if (code !== 0) return;
      if (flag == "disease") {
        this.disease_data = [...this.disease_data, ...results.disease_data];
        this.disease_pn = results.disease_pn;
        this.disease_ps = results.disease_ps;
        this.disease_count = results.disease_count;
      } else if (flag == "insect") {
        this.insect_data = [...this.insect_data, ...results.insect_data];
        this.insect_pn = results.insect_pn;
        this.insect_ps = results.insect_ps;
        this.insect_count = results.insect_count;
      } else {
        this.insect_data = [...this.insect_data, ...results.insect_data];
        this.insect_pn = results.insect_pn;
        this.insect_ps = results.insect_ps;
        this.insect_count = results.insect_count;
        this.disease_data = [...this.disease_data, ...results.disease_data];
        this.disease_pn = results.disease_pn;
        this.disease_ps = results.disease_ps;
        this.disease_count = results.disease_count;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.knowledge {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 77px 0 23px;
  display: flex;
  justify-content: space-between;

  .column-R {
    width: 582px;
    height: 960px;

    .focus {
      width: 100%;
      height: 466px;
      background: #0e2138;

      .f-title {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 19px 47px 0 47px;

        img {
          margin-right: 14px;
          width: 24px;
          height: 24px;
        }

        font-size: 22px;
        color: #67c23a;
      }

      .banner-box {
        width: 490px;
        height: 381px;
        background: #071a2c;
        margin: 0 auto;
        padding: 20px 22px;

        .banner-main {
          width: 446px;
          height: 296px !important;
          margin-bottom: 23px;

          /deep/.el-carousel__indicators {
            display: none;
          }

          /deep/.el-carousel__container {
            height: 100% !important;
          }

          .banner-imgs {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        .banner-info {
          width: 100%;
          display: flex;
          align-items: center;

          .title {
            width: calc(100% - 54px);
            font-size: 18px;
            color: #fff;
            cursor: pointer;

            &:hover {
              color: #3e90e5;
            }
          }

          .control {
            width: 54px;
            display: flex;
            align-items: center;

            .prev-img,
            .next-img {
              width: 7px;
              height: 12px;
              cursor: pointer;
            }

            .index-count {
              color: #fff;
              font-size: 14px;
              margin: 0 10px;

              span {
                &:first-of-type {
                  color: #3e90e5;
                }
              }
            }
          }
        }
      }
    }

    .news {
      width: 100%;
      height: 490px;
      background: #0e2138;
      padding: 19px 35px;
      padding-right: 30px;

      .r-lists {
        width: 100%;
        height: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding-right: 5px;

        .r-items {
          border-bottom: 1px solid #213a59;
          width: 100%;
          height: 47px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          &:hover .summary {
            color: #3e90e5;
          }

          .summary {
            width: 70%;
            height: 100%;
            line-height: 47px;
            font-size: 14px;
            color: #fff;
            padding: 0 18px;
            position: relative;

            &::before {
              content: "";
              width: 6px;
              height: 6px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0px;
              background: #3e90e5;
              border-radius: 50%;
            }
          }

          .time {
            width: 30%;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #b6c0cb;
            text-align: right;

            span {
              width: 100%;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
    }
  }

  .column-L {
    flex: 1;
    height: 960px;
    padding: 19px 0;
    // background: #0e2138;
    margin: 0 12px 0 0;
    display: flex;

    .screen {
      width: 372px;
      height: 100%;
      background: #142d4c;
      padding: 15px 0 15px 40px;

      .screen-title {
        display: flex;
        margin-bottom: 25px;
        padding-right: 30px;
        align-items: flex-start;

        .left-line {
          background-color: #3e90e5;
          width: 4px;
          height: 22px;
          border-radius: 2px;
          margin: 0 11px 0 0;
        }

        p {
          color: #fff;
          font-size: 22px;
          font-weight: 400;
        }
      }

      .calamity-type {
        width: 256px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 8px;
        margin: 0 0 35px 0;

        .calamity-item {
          width: 116px;
          height: 83px;
          background: #67c23a;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          cursor: pointer;
          &.active::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 13px solid #67c23a;
            border-left: 19px solid transparent;
            border-right: 19px solid transparent;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
          }

          &:first-of-type {
            background: #3e90e5;
          }
          &.active:first-of-type::after {
            border-top-color: #3e90e5;
          }

          .calamity-img {
            width: 38px;
            height: 38px;
          }

          .calamity-title {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
          }
        }
      }

      /deep/.calamity-crops-class {
        width: 100%;
        height: 80%;
        overflow-y: auto;
        padding: 10px 0;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }

        .know-detailed {
          width: 256px;
          min-height: 36px;
          background: #224d7f;
          border-radius: 5px;
          line-height: 36px;
          color: #fff;
          font-size: 14px;
          padding: 0 20px;
          margin-bottom: 13px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .know-detailed-arrow {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;

            .arrow-img {
              width: 14px;
              height: 8px;
              margin-left: 11px;
              transition: all 0.5s;

              &.active {
                transform: rotate(180deg);
              }
            }
          }
        }

        .el-collapse {
          border: none;
        }

        .el-collapse-item__wrap {
          background-color: #142d4c;
          border: none;
          font-size: 12px;
          font-weight: 400;
          color: #fff;
        }

        .el-collapse-item__header {
          border: none;
          background-color: inherit;
          display: inherit;
          height: auto;
        }

        .know-detailed-content {
          transition: all 0.5s;

          .crops-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .crops-item {
              min-width: 76px;
              height: 28px;
              line-height: 28px;
              background: #173355;
              border: 1px solid #1b3e68;
              border-radius: 2px;
              margin: 0 13px 13px 0;
              color: #fff;
              font-size: 14px;
              text-align: center;
              padding: 0 10px;
              cursor: pointer;

              &:nth-of-type(3n) {
                margin-right: 0;
              }

              &.active {
                background: #3e90e5;
              }
            }
          }
        }

        .el-collapse-item__arrow {
          display: none;
        }
      }
    }

    .result {
      width: 951px;
      height: 100%;
      background: #071a2c;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .result-top {
        width: 100%;
        height: 126px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .result-search {
          height: 126px;
          width: calc(100% - 310px);
          background: #0e2138;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 44px;

          .per-class {
            margin-right: 16px;

            /deep/.el-select {
              .el-input__inner {
                width: 102px;
                background-color: #081c30;
                border: 1px solid #385576;
                color: #57708d;
                font-size: 14px;
              }
            }
          }

          .name {
            margin-left: 36px;
            display: flex;
            align-items: center;

            span {
              font-size: 14px;
              margin-right: 20px;
            }

            /deep/.el-input {
              width: 200px;
            }

            /deep/.el-input__inner {
              width: 200px;
              background-color: #081c30;
              border: 1px solid #385576;
              color: #57708d;
              font-size: 14px;
            }
          }

          .searchBtn {
            width: 90px;
            height: 40px;
            background: #3e90e5;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;
            margin-left: 20px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }

          .calamity-type-name.name {
            margin-left: 0;

            /deep/.el-input {
              width: 253px;

              .el-input__inner {
                width: 100%;
              }
            }
          }
        }

        .result-chat {
          width: 296px;
          height: 106px;
          cursor: pointer;
        }
      }

      .show-box {
        width: 100%;
        height: calc(100% - 138px);

        .disease-list {
          width: 100%;
          height: 100%;
          background: #0e2138;
          display: flex;
          flex-direction: column;
          padding: 32px 0 0 0;

          .disease-list-items {
            height: 50%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .disease-list-items-title {
              width: 237px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              background: #3e90e5;
              border-radius: 0 5px 5px 0;
              font-size: 14px;
              color: #fff;
              margin-bottom: 20px;
            }

            .disease-list-items-main,
            .insect-box,
            .weed-box,
            .disease-box {
              width: 100%;
              height: calc(100% - 56px);
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              padding: 0 0 0 54px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 5px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #3e90e5;
              }
              .disease-list-items-main-items {
                width: 18%;
                height: 36px;
                line-height: 36px;
                padding: 0 12px;
                font-size: 12px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                margin: 0 10px 24px 0;
                border-left: 1px solid #0e2138;
                &:hover {
                  color: #3e90e5;
                }

                &.active {
                  background: #173355;
                  border-left-color: #3e90e5;
                }
              }
            }
            .insect-box {
              height: calc(100% - 60px);
            }
          }
        }
      }
    }

    .info-box {
      width: 951px;
      height: 100%;
      background: #071a2c;
      padding: 0 40px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }

      .info-title {
        width: 100%;
        display: flex;
        margin-bottom: 25px;
        padding-right: 30px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .info-left-line {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          padding: 0 0 0 15px;
          position: relative;

          &::before {
            content: "";
            background-color: #3e90e5;
            width: 4px;
            height: 18px;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }

        .return-btn {
          width: 90px;
          height: 33px;
          background: #224d7f;
          border-radius: 17px;
          font-size: 16px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          img {
            width: 17px;
            height: 17px;
            margin-right: 8px;
          }
        }
      }

      .cell-title {
        width: 100%;
        border-bottom: 1px solid #224d7f;
        margin-bottom: 28px;

        span {
          display: inline-block;
          width: 104px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #224d7f;
          border-radius: 2px 2px 0px 0px;
          font-size: 14px;
          color: #fff;
        }
      }

      .other-col {
        margin-bottom: 15px;
        width: 100%;
        display: flex;

        .content-title {
          width: 81px;
          height: 25px;
          background: #081c30;
          font-size: 14px;
          color: #3e90e5;
          display: inline-block;
          margin-right: 12px;
          line-height: 25px;
          text-align: center;
        }

        .content-main {
          width: calc(100% - 81px);
          font-size: 14px;
          color: #fff;
          display: inline-block;
          line-height: 25px;
        }
      }

      .imgs-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        img,
        iframe {
          width: 197px;
          height: 144px;
          margin: 0 24px 14px 0;
          cursor: pointer;

          &:nth-of-type(4n) {
            margin-right: 0;
          }
        }
      }

      .means-list {
        width: 100%;

        .means-list-items {
          width: 100%;

          .p-title {
            width: 104px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #173355;
            border-radius: 16px 16px 16px 0px;
            font-size: 14px;
            color: #3e90e5;
            margin-bottom: 12px;
          }

          .p-value {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .know-pop {
    /deep/.el-dialog {
      width: 908px !important;
      background-color: inherit;
      margin-top: 0 !important;
    }

    /deep/.el-dialog__header {
      padding: 0;
      padding-top: 18px !important;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 36px;
      top: 63px;
      right: 58px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    .know-outbox {
      width: 908px;
      height: 852px;
      background: url(../assets/image/know/bgc.png) no-repeat center center;
      background-size: cover;
      padding: 58px 55px 0 55px;

      .ware-title {
        display: flex;
        margin-bottom: 40px;
        padding-right: 30px;
        align-items: flex-start;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin: 5px 10px 0 0;
        }

        p {
          flex: 1;
          line-height: 30px;
          font-size: 22px;
          color: #fff;
          font-weight: bold;
        }
      }

      .know-main {
        display: flex;
        flex-direction: column;

        .know-detailed {
          width: 780px;
          height: 37px;
          background: #3e90e5;
          border-radius: 15px 0px 15px 0px;
          line-height: 37px;
          font-size: 16px;
          color: #fff;
          margin-bottom: 26px;
          padding: 0 17px;
        }

        .know-brief-content {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 38px;

          .know-brief-content-banner {
            width: 268px;
            height: 201px;
            margin-right: 19px;

            /deep/.carousel {
              height: 100% !important;

              .el-carousel__container {
                height: 100% !important;
              }

              .carousel-imgs {
                width: 100%;
                height: 100%;
              }
            }
          }

          .know-brief-content-other {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            width: calc(100% - 268px);
            height: 185px;

            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #3e90e5;
            }

            .other-col {
              margin-bottom: 15px;
              width: 100%;
              display: flex;

              .content-title {
                width: 81px;
                height: 25px;
                background: #081c30;
                font-size: 14px;
                color: #3e90e5;
                display: inline-block;
                margin-right: 12px;
                line-height: 25px;
                text-align: center;
              }

              .content-main {
                width: calc(100% - 81px);
                font-size: 14px;
                color: #fff;
                display: inline-block;
                line-height: 25px;
              }
            }
          }
        }

        .know-scroll-box {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 400px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #3e90e5;
          }

          .article {
            color: #fff;
            line-height: 20px;
            font-size: 14px;
            text-indent: 2em;
          }
        }

        .know-detailed {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 22px;

          .know-detailed-arrow {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;

            .arrow-img {
              width: 14px;
              height: 8px;
              margin-left: 11px;
              transition: all 0.5s;

              &.active {
                transform: rotate(180deg);
              }
            }
          }
        }

        .know-detailed-content {
          color: #fff;
          font-size: 14px;
          line-height: 30px;
          transition: all 0.5s;
          margin-bottom: 22px;
        }

        /deep/.el-collapse {
          border: none;
        }

        /deep/.el-collapse-item__wrap {
          background-color: #0f243d;
          border: none;
        }

        /deep/.el-collapse-item__header {
          border: none;
          background-color: inherit;
          display: inherit;
          height: auto;
        }

        /deep/.el-collapse-item__arrow {
          display: none;
        }

        .know-other-title {
          font-size: 14px;
          color: #3e90e5;
          width: 100%;
          height: 35px;
          background: #081c30;
          line-height: 35px;
          padding: 0 17px;
          margin-bottom: 17px;
        }

        .know-other-cont {
          width: 100%;
          min-height: 43px;
          font-size: 14px;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 32px;
        }
      }
    }
  }
}
</style>
